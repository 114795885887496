<template>
  <div class="auth-container">
    <div class="auth-form">
      <transition name="component-fade" mode="out-in">
        <b-form key="reset" @submit.prevent="onSubmit" class="auth-form__body">
          <div class="auth-form__title">
            <p>{{ $t('header.passwordRecovery') }}</p>
          </div>

          <AppInput
            type="password"
            ref="password"
            name="password"
            :label="$t('placeholders.createPassword')"
            :state="
              $v.form.password.$error || error ? false : !$v.form.password.$invalid ? true : null
            "
            @blur="setField"
          />

          <AppInput
            class="auth-form__password-input"
            type="password"
            ref="passwordRepeat"
            name="passwordRepeat"
            :label="$t('placeholders.repeatPassword')"
            :invalidFeedback="errorLabel"
            :state="
              ($v.form.password.$error ? false : !$v.form.password.$invalid ? true : null) &&
              ($v.form.passwordRepeat.$error || error
                ? false
                : !$v.form.passwordRepeat.$invalid
                ? true
                : null)
            "
            @blur="setField"
          />
          <div class="recover f-center mb-0">
            <span class="link link--color-gray" @click="$router.push('/auth')">{{
              $t('buttonLabels.login')
            }}</span>
          </div>
          <b-button class="mt-3" type="submit" variant="primary" :disabled="loading">
            <span v-if="!loading">{{ $t('buttonLabels.restore') }}</span>
            <span v-else>
              <b-spinner small type="grow"></b-spinner>
              {{ $t('label.processing') }}
            </span>
          </b-button>
        </b-form>
      </transition>
    </div>
  </div>
</template>

<script>
import { required, sameAs, helpers } from 'vuelidate/lib/validators';
import AppInput from '@/components/AppInput.vue';
import focusOnValidation from '@/mixins/focusOnValidation';
import AuthService from '@/services/auth.service';

const passwordMatch = helpers.regex('passwordMatch', /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/);

export default {
  name: 'ResetPassword',
  components: { AppInput },
  mixins: [focusOnValidation],
  data: () => ({
    form: {
      password: '',
      passwordRepeat: '',
    },
    error: null,
    loading: false,
  }),
  validations: {
    form: {
      password: {
        required,
        passwordMatch,
      },
      passwordRepeat: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
  computed: {
    errorLabel() {
      if (this.error) {
        return this.error;
      }
      return this.getErrorMessage('password') || this.getErrorMessage('passwordRepeat');
    },
  },
  methods: {
    setField(e) {
      const fieldName = e.target.name;
      this.form[fieldName] = e.target.value;
      this.error = null;
      this.$v.form[fieldName].$touch();
    },
    onSubmit(event) {
      event.preventDefault();
      this.error = null;
      this.$v.$reset();

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if (!this.$route.query?.token) {
        return (this.error = this.$t('errorMessages.invalidLinkForResetPassword'));
      }

      this.loading = true;

      AuthService.resetPassword({ password: this.form.password, token: this.$route.query.token })
        .then(this.authUser)
        .catch((error) => {
          const status = error.response?.status;
          this.loading = false;
          if (status === 404) {
            this.error = this.$t('errorMessages.invalidLinkForResetPassword');
          } else if (status === 401) {
            this.error = this.$t('errorMessages.passwordMustContain');
          } else {
            this.error = this.$t('errorMessages.somethingWentWrong');
          }
        });
    },
    authUser({ data }) {
      if (!data?.email) {
        return (this.error = this.$t('errorMessages.somethingWentWrong'));
      }
      this.$store
        .dispatch('auth/authorization', {
          username: data.email,
          password: this.form.password,
        })
        .then(() => {
          this.$router.push('/');
        })
        .catch(() => {
          this.loading = false;
          this.error = this.$t('errorMessages.somethingWentWrong');
        });
    },
  },
};
</script>
